
import styles from './ListItemWithImage.module.css';


const ListItemWithImage = ({ date, time, title, description, status }) => {
  	return (
		<div className={styles.listItemWithImage}>
			<div className={styles.imageIcon} alt=""/>
			<div className={styles.text}>
				<div className={styles.title}>
					<div className={styles.rating}>
						<div className={styles.div}>{date}</div>
					</div>
					<div className={styles.rating1}>
						<div className={styles.div}>{time}</div>
					</div>
					<div className={styles.foremIpsum}>{title}</div>
					<div className={styles.rating2}>
						<div className={styles.div}>{description}</div>
					</div>
				</div>
			</div>
			<div className={styles.tags}>
				<div className={styles.tags1}>
					<b className={styles.assigned}>Assigné</b>
				</div>
			</div>
		</div>);
};

export default ListItemWithImage;
