import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './components/Dashboard';
import ListTasks from './components/TaskList/ListTable';
import Product from './components/Product';
import "./App.css";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Customer from "./components/Customer";
import Flypro from "./components/Flypro";
import User from "./components/User";
import Promotion from "./components/Promotion";
import Management from "./components/Management";
import Hotels from "./components/Hotels";
import Notification from "./components/Notification";
import Calendar from "./components/Calendar";
const ROLES = {
  Client: "ROLE_CLIENT",
  Editor: "ROLE_USER",
  Admin: "ROLE_ADMIN",
};
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="forget_password" element={<ForgetPassword />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="Customer" element={<Customer />} />
            <Route path="Flypro" element={<Flypro />} />
            <Route path="User" element={<User />} />
            <Route path="notification" element={<Notification />} />
            <Route path="products" element={<Product />} />
            <Route path="Promotion" element={<Promotion />} />
            <Route path="Management" element={<Management />} />
            <Route path="Hotels" element={<Hotels />} />
            <Route path="tasks" element={<ListTasks />} />
            <Route path="calendar" element={<Calendar />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
