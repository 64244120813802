import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import { endpoints, exportClientBookingsUrl, exportDirectHotelBookingsUrl, getHotelBookingsUrl, createHotelAccount } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import HotelService from "../services/hotel.service";
import UserService from "../services/user.service";
import BookingService from "../services/booking.service";
import { addressFormat } from '../shared/utils/string';

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
  },
};

const Hotels = () => {
  Modal.setAppElement("#root");

  const handleExportClientBookings = async (hotelId) => {
    const url = exportClientBookingsUrl(hotelId);
    try {
      // Fetch the file as blob
      const response = await axiosPrivate.get(url, {
        responseType: 'blob',
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'client_bookings.xlsx';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting client bookings:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'An error occurred!',
      });
    }
  };

  const handleExportDirectHotelBookings = async (hotelId) => {
    try {
      const url = exportDirectHotelBookingsUrl(hotelId);

      // Fetch the file as blob
      const response = await axiosPrivate.get(url, {
        responseType: 'blob',
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary link to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'direct_hotel_bookings.xlsx';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting direct hotel bookings:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'An error occurred during export.',
      });
    }
  };

  const notify = useNotifications();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [allBookings, setAllBookings] = useState([]);
  const [directBookings, setDirectBookings] = useState([]);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [accountFormData, setAccountFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    role: '',
    hotel_id: ''
  });

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    contact_first_name: "",
    contact_last_name: "",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    country: "Switzerland",
    country_code: "CH",
    flyproInstructions: "",
    duo_capability: false,
  });

  const axiosPrivate = useAxiosPrivate();

  // Fetch Hotels
  const fetchHotels = async () => {
    try {
      const response = await HotelService.index({ with: 'address,account' }, axiosPrivate);
      console.log('Fetched Hotels:', response);
      setHotels(response);
    } catch (error) {

    }
  };

  const fetchBookings = async (id) => {
    try {
      const response = await BookingService.index({ with: 'creator,product,client' }, axiosPrivate);
      console.log('Fetched bookings:', response);
      setAllBookings(response.account.bookings);
      setDirectBookings(response.directBookings);
      setHotels(response);
    } catch (error) {

    }
  };


  useEffect(() => {
    fetchHotels();
  }, []);

  const closeModalManage = () => {
    setIsOpenManage(false);
    setSelectedHotel(null);
  };

  const openModalManage = (hotel) => {
    console.log('Hotel : ', hotel);
    setSelectedHotel(hotel);
    fetchBookings(hotel.id);
    setIsOpenManage(true);
  };

  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      duo_capability: event.target.checked,
    }));
  };

  const handleAccountFormChange = (e) => {
    const { name, value } = e.target;
    setAccountFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    if (e.target.name === 'state') {
      let selected = cantons.cantons.find(
        (canton) => canton.name === e.target.value
      );
      document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range);
      setFormData({
        ...formData, zip_code: ''
      });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );

    const [start, end] = state.zip_code_range.split("–").map(Number);
    if (formData.zip_code === "" || (formData.zip_code < start || formData.zip_code > end)) {
      notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
      setFormData({
        ...formData,
        zip_code: '',
      });
      return;
    }
    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.state_code = state?.abbreviation;
    formData.role = 'ROLE_CLIENT';
    formData.country = "Switzerland";
    formData.country_code = "CH";
    try {
      const response = await HotelService.store(formData, axiosPrivate);
      if (response.data) {
        closeModal();
        fetchHotels();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const handleAccountFormSubmit = async (e, hotelId) => {
    e.preventDefault();

    if (!accountFormData.email || !accountFormData.firstname || !accountFormData.lastname) {
      notify.warn(
        "Please fill out all required fields!"
      );
      return;
    }

    try {
      accountFormData.role = 'ROLE_HOTEL_MANAGER';
      accountFormData.hotel_id = selectedHotel.id;
      const response = await UserService.store(accountFormData, axiosPrivate);
      setFormData(response)
      setIsAccountModalOpen(false);

    } catch (error) {


    }
  };

  const openModal = () => {
    setIsOpen(true);
    setFormData({
      name: "",
      address: "",
      phone: "",
      email: "",
      contact_first_name: "",
      contact_last_name: "",
      flyproInstructions: "",
      duo_capability: false,
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Open account modal
  const handleCreateAccountClick = () => {
    setIsAccountModalOpen(true);
  };

  // Close account modal
  const handleModalClose = () => {
    setIsAccountModalOpen(false);
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input type="text" placeholder="Search by Hotel Name..." className="form-text" />
              <input type="submit" className="form-submit" value="Search" />
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Add a Hotel
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead className="no-background">
              <tr>
                <th>Hotel name</th>
                <th>Hotel address</th>
                <th>Contact Name</th>
                <th>Phone Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {hotels.length > 0 ? (
                hotels.map((hotel) => (
                  <tr key={hotel.id}>
                    <td>{hotel.name}</td>
                    <td>
                      {addressFormat(hotel.address)}
                    </td>
                    <td>{hotel.contact_first_name + " " + hotel.contact_last_name}</td>
                    <td>{hotel.phone}</td>
                    <td>
                      <a href="#" onClick={() => openModalManage(hotel)} className="see-more edit">
                        See more
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No hotels found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Hotel"
      >
        <button onClick={closeModal}>Close</button>
        <h2>Add a Hotel</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Hotel Name*</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Street Number</label>
                <input value={formData.street_number} type="text" name="street_number" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>City</label>
                <input value={formData.city} type="text" name="city" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Street</label>
              <input value={formData.street} type="text" name="street" onChange={handleChange} />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Canton</label>
                <select name="state" onChange={handleChange}>
                  <option value="">-- Select a Canton --</option>
                  {cantons.cantons.map((canton) => (
                    <option key={canton.abbreviation} value={canton.name}>
                      {canton.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-text">
                <label>Code ZIP</label>
                <input value={formData.zip_code} type="text" name="zip_code" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Contact First Name*</label>
              <input type="text" name="contact_first_name" value={formData.contact_first_name} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Contact Last Name*</label>
              <input type="text" name="contact_last_name" value={formData.contact_last_name} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Phone Number*</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Email</label>
              <input type="text" name="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="form-text topMargArea">
              <label>Flypro Instructions</label>
              <textarea
                className="formTextArea"
                value={formData.flyproInstructions}
                name="flyproInstructions"
                placeholder="Enter Flypro Instructions..."
                onChange={handleChange}
              />
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.duo_capability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <button type="submit" className="btn-submit">
              Create a Hotel
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenManage && selectedHotel !== null}
        onRequestClose={closeModalManage}
        style={customStyles}
        contentLabel="Manage Hotel"
      >
        <div className="form-inscription mrgBottom">
          <h2>{selectedHotel?.name}</h2>
          <form>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Address</label>
              <input type="text" name="address" value={addressFormat(selectedHotel?.address)} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Contact Name:</label>
              <input type="text" name="contact_first_name" value={`${selectedHotel?.contact_first_name || ''} ${selectedHotel?.contact_last_name || ''}`.trim()} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Phone Number*</label>
              <input type="text" name="phone" value={selectedHotel?.phone || ''} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Email</label>
              <input type="text" name="email" value={selectedHotel?.email || ''} readOnly />
            </div>
            <div className="account-hotel-management">
              <div className="input-hotel-container">
                <label htmlFor="accountManagement" className="input-hotel-label">Account Management</label>
                <input
                  type="text"
                  id="accountManagement"
                  name="accountManagement"
                  readOnly
                  value=" "
                />
                {selectedHotel?.account ? (
                  <span className="hotel-account">{selectedHotel.account.extra.full_name}</span>
                ) : (
                  <a href="#" onClick={(e) => handleCreateAccountClick(e, selectedHotel.id)} className="create-hotel-account">
                    Create Account
                  </a>
                )}
              </div>
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginRight: '220px' }}>
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={selectedHotel?.duo_capability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <div className="form-text booking-zone topMargArea">
              <span className="list-all-bookings">Hotel Bookings:</span>
              <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
              <table className="table-bookings">
                <thead>
                  <tr>
                    <th>Booking ID</th>
                    <th>Client</th>
                    <th>Date</th>
                    <th>Treatment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allBookings.length > 0 ? (
                    allBookings.map((booking) => (
                      <tr key={booking.id}>
                        <td>{booking.id}</td>
                        <td>{booking.client}</td>
                        <td>{booking.date ? new Date(booking.date).toISOString().split('T')[0] : 'N/A'}</td>
                        <td>{booking.treatmentType}</td>
                        <td>{booking.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No bookings found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="extract-button" onClick={() => handleExportClientBookings(selectedHotel.id)}>
                Extract Booking
              </div>
            </div>
            <div className="form-text booking-zone extractMrg">
              <span className="list-all-bookings">Hotel Direct Bookings:</span>
              <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
              <table className="table-bookings">
                <thead>
                  <tr>
                    <th>Booking ID</th>
                    <th>Client</th>
                    <th>Date</th>
                    <th>Treatment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
              <div className="extract-button" onClick={() => handleExportDirectHotelBookings(selectedHotel.id)}>
                Extract Booking
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
        contentLabel="Create Hotel Account"
      >
        <button onClick={handleModalClose}>Close</button>
        <h2>Create Hotel Account</h2>
        <form onSubmit={(e) => handleAccountFormSubmit(e, selectedHotel.id)}>
          <div className="form-inscription mrgBottom">
            <div className="form-text">
              <label>Email*</label>
              <input
                type="email"
                name="email"
                value={accountFormData.email}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>First Name*</label>
              <input
                type="text"
                name="firstname"
                value={accountFormData.firstname}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastname"
                value={accountFormData.lastname}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={accountFormData.phone}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <button type="submit" className="btn-submit">
              Create Account
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Hotels;
