export const asTitle = (input) => {
    return input
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ').split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export const addressFormat = (input) => {
    return `${input?.street_number?? ''}${input?.street_number ? ', ' : ''}${input?.street?? ''}${input?.street ? ', ' : ''}${input?.city?? ''}${input?.city ? ', ' : ''}${input?.zip_code?? ''}, ${input?.state_code?? ''}, ${input?.country_code?? ''}`
}