import { endpoints } from '../utils/utils';
import useAuth from './useAuth';
import useAxiosDefault from "./useAxiosDefault";

const useRefreshToken = () => {
    // eslint-disable-next-line no-unused-vars
    const { auth, setAuth } = useAuth();
    const axiosDefault = useAxiosDefault();
    const refresh = async () => {
        const response = await axiosDefault.get(endpoints.refreshToken, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });
        if (response) {
            setAuth(prev => {
                /* console.log(JSON.stringify(prev));
                console.log(response.data.token); */
                return {
                    ...prev,
                    roles: response?.data?.user?.roles,
                    accessToken: response.data.token
                }
            });
            return response.data.token;
        }
        return refresh;
    }
    return refresh;
};

export default useRefreshToken;
