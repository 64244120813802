// ChooseLocation.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import UserService from "../services/user.service";
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import { asTitle } from '../shared/utils/string';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "95vh",
    scrollbarWidth: "none",
  },
};
const Customer = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const notify = useNotifications();
  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [loading, setLoading] = useState(true);   // Pour afficher un état de chargement
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const limit = 100; // Nombre de clients par page
  const [formData, setFormData] = useState({
    id: null, //
    civility: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    birthdate: "",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    country: "Switzerland",
    country_code: "CH",
    role: "ROLE_CLIENT"
  });
  const [filters, setFilters] = useState({
    with: 'primaryaddress'
  });
  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };
  // Fonction pour mettre à jour les valeurs du formulaire
  const handleChange = (e) => {
    if (e.target.name === 'state') {
      let selected = cantons.cantons.find(
        (canton) => canton.name === e.target.value
      );
      document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range);
      setFormData({
        ...formData, zip_code: ''
      });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );

    const [start, end] = state.zip_code_range.split("–").map(Number);
    if (formData.zip_code === "" || (formData.zip_code < start || formData.zip_code > end)) {
      notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
      setFormData({
        ...formData,
        zip_code: '',
      });
      return;
    }
    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.state_code = state?.abbreviation;
    formData.role = 'ROLE_CLIENT';
    formData.country = "Switzerland";
    formData.country_code = "CH";
    try {
      const response = await UserService.store(formData, axiosPrivate);
      if (response.data) {
        closeModal();
        fetchCustomers();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_CLIENT', axiosPrivate);
      setCustomers(response); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
      // setTotalPages(response.data.totalPages || 2); // Le nombre total de pages si disponible dans la réponse
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
    }
  };

  // Fonction pour calculer la date minimale (18 ans avant aujourd'hui)
  const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18, // Soustraire 18 ans à l'année actuelle
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0]; // Formater la date en YYYY-MM-DD
  };
  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {


    fetchCustomers();
  }, [currentPage]); // Recharger les données à chaque changement de page

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };


  function openModal(customer) {
    if (customer && customer?.id) {
      let phone = customer.phone;
      if (phone && phone.startsWith("0")) {
        phone = "+41" + phone.substring(1);
      }
      setFormData({
        ...formData,
        ...customer,
        ...customer.primaryaddress,
        phone: phone
      });
    }
    setIsOpen(true);
  }



  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setFormData({
      id: null,
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zip_code: "",
      type: "client"
    });
    setIsOpen(false);
  }

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select>
              <option value="">Name</option>
              <option value="Name">name</option>
            </select>
            <select>
              <option value="">Contains</option>
              <option value="Name">Contains</option>
            </select>
            <select>
              <option value="">All Filters</option>
              <option value="Name">All Filters</option>
            </select>
            <select>
              <option value="">All Tags</option>
              <option value="Name">All Tags</option>
            </select>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Add a customer
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Image</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Registration Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers?.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img src={image} alt="FlySpa" />
                    </td>
                    <td>{customer.firstname} {customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>{customer.created_at}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {asTitle(customer.status)}
                    </td>
                    <td>
                      <a onClick={() => openModal(customer)} className="edit">
                        Edit
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No Client Found.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="pagination-button"
            >
              Précédent
            </button>
            <span>
              Page {currentPage + 1} sur {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages - 1}
              className="pagination-button"
            >
              Suivant
            </button>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2>Add a customer</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MS"
                  id="madame"
                  checked={formData.civility === "MS"}
                  onChange={handleChange}
                />
                <label htmlFor="madame">Ms</label>
              </div>
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MR"
                  checked={formData.civility === "MR"}
                  id="monsieur"
                  onChange={handleChange}
                />
                <label htmlFor="monsieur">Mr</label>
              </div>
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Firstname</label>
                <input required value={formData.firstname} type="text" name="firstname" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>Lastname</label>
                <input required value={formData.lastname} type="text" name="lastname" onChange={handleChange} />
              </div>
            </div>

            <div className="form-text">
              <label>Phone</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                ariaHideApp="false"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />
            </div>

            <div className="form-text">
              <label>Email Address</label>
              <input required value={formData.email} type="email" name="email" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Birthdate</label>
              <input
                type="date"
                name="birthdate"
                value={formData.birthdate}
                onChange={handleChange}
                max={calculateMinDate()} // Date maximum permise (il y a 18 ans)
              />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Street Number</label>
                <input value={formData.street_number} type="text" name="street_number" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>City</label>
                <input value={formData.city} type="text" name="city" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Street</label>
              <input value={formData.street} type="text" name="street" onChange={handleChange} />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Canton</label>
                <select name="state" onChange={handleChange}>
                  <option value="">-- Select a Canton --</option>
                  {cantons.cantons.map((canton) => (
                    <option key={canton.abbreviation} value={canton.name}>
                      {canton.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-text">
                <label>Code ZIP</label>
                <input value={formData.zip_code} type="text" name="zip_code" onChange={handleChange} />
              </div>
            </div>
            <button type="submit" className="btn-submit">
              {formData.id ? "Mettre à jour" : "Créer un compte"}

            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Customer;
