import styles from './Commande.module.css';
import "./Commande.css";
import React, { useState } from 'react';

const TimelineItem = ({ eventTitle, eventDate }) => {
    return (
        <li className="row timeline-item">
            <h3 className="timeline-item-title"></h3>
            <div className="timeline-item-selector"></div>
            <div className="timeline-item-date"></div>
        </li>
    );
};

const TimelineName = ({ eventData }) => {
    return (
        <ul className="timeline">
            {eventData.map((item, index) => (
                <TimelineItem key={index} eventTitle={item.eventTitle} eventDate={item.eventDate} />
            ))}
        </ul>
    );
};

const Commande = ({ orderId, status, taskDescription, startTime, completeTime, team, pro, orderID, totalTime, distance}) => {
    const [activeTab, setActiveTab] = useState('details');

    const data = [
        { eventTitle: "JSConf 2015 Europe", eventDate: "November 24th 2015" },
        { eventTitle: "React.JS 2015", eventDate: "December 12th 2015" },
        { eventTitle: "Ember Power San Jose Annual Conf", eventDate: "December 24th 2015" },
    ];

  	return (
        <div className={styles.listItemWithImage}>
            <div className={styles.title}>commande / {orderId}</div>
            <div className="tabs">
                <div className="tab-group">
                <div
                    className={`label1 ${activeTab === "details" ? "active" : ""}`}
                    onClick={() => setActiveTab("details")}
                >
                    <div className="state-layer">
                    <div className="tab-contents">
                        <div className="label2">Details</div>
                        {activeTab === "details" && (
                        <div className="indicator">
                            <div className="shape"></div>
                        </div>
                        )}
                    </div>
                    </div>
                </div>
                <div
                    className={`tab22 ${activeTab === "client" ? "active" : ""}`}
                    onClick={() => setActiveTab("client")}
                >
                    <div className="state-layer">
                    <div className="tab-contents">
                        <div className="label2">Client</div>
                        {activeTab === "client" && (
                        <div className="indicator">
                            <div className="shape"></div>
                        </div>
                        )}
                    </div>
                    </div>
                </div>
                <div
                    className={`styles.tab22 ${activeTab === "historique" ? "active" : ""}`}
                    onClick={() => setActiveTab("historique")}
                >
                    <div className="state-layer">
                    <div className="tab-contents">
                        <div className="label2">Historique</div>
                        {activeTab === "historique" && (
                        <div className="indicator">
                            <div className="shape"></div>
                        </div>
                        )}
                    </div>
                    </div>
                </div>
                </div>
            </div>

            {activeTab === 'details' && (
                <>
                    <div className={styles.title1}>
                        <p className={styles.statusSuccessful}>Status: {status}</p>
                        <p className={styles.statusSuccessful}>
                            <span>{`Task Description: `}</span>
                            <b className={styles.antiStressLp}>{taskDescription}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span className={styles.antiStressLp}>{`Start Before: `}</span>
                            <b>{startTime}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span className={styles.antiStressLp}>{`Complete Before: `}</span>
                            <b>{completeTime}</b>
                        </p>
                        <p className={styles.statusSuccessful}>{`Tracking Link (Unseen): `}</p>
                        <p className={styles.statusSuccessful}>&nbsp;</p>
                        <p className={styles.statusSuccessful}>&nbsp;</p>
                        <p className={styles.statusSuccessful}>&nbsp;</p>
                        <p className={styles.statusSuccessful}>
                            <span>{`Team: `}</span>
                            <b className={styles.antiStressLp}>{team}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span className={styles.antiStressLp}>{`P/pro: `}</span>
                            <b>{pro}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span className={styles.antiStressLp}>{`Order ID: `}</span>
                            <b>{orderID}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span>Total Time Taken:</span>
                            <b className={styles.antiStressLp}> {totalTime}</b>
                        </p>
                        <p className={styles.statusSuccessful}>
                            <span>{`Distance Traveled: `}</span>
                            <b className={styles.antiStressLp}>{distance}</b>
                        </p>
                    </div>
                    <div className={styles.groupParent}>
                        <div className={styles.rectangleParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.enRouteParent}>
                                    <div className={styles.enRoute}>En route</div>
                                    <span className={styles.vectorIcon1}/>
                            </div>
                        </div>
                        <div className={styles.rectangleGroup}>
                            <div className={styles.groupItem} />
                            <div className={styles.prparationParent}>
                                    <div className={styles.prparation}>Préparation</div>
                                    <span className={styles.vectorIcon2}/>
                            </div>
                        </div>
                        <div className={styles.rectangleContainer}>
                            <div className={styles.groupInner} />
                            <div className={styles.massageParent}>
                                    <div className={styles.massage}>Massage</div>
                                    <span className={styles.vectorIcon3}/>
                            </div>
                        </div>
                        <div className={styles.groupDiv}>
                            <div className={styles.groupInner} />
                            <div className={styles.terminParent}>
                                    <div className={styles.termin}>Terminé</div>
                                    <span className={styles.vectorIcon4} />
                            </div>
                        </div>
                        <span className={styles.frameChild}/>
                    </div>
                </>
            )}
            {activeTab === 'client' && (
                <>
                    <div className={styles.generalInfo}>
                        Informations sur les clients
                    </div>
                    <div className={styles.titleParentClient}>
                    <div className={styles.titleClient}>
                        <p className={styles.nameClient}>{`Name: `}</p>
                        <p className={styles.nameClient}>Contact:</p>
                        <p className={styles.nameClient}>{`Email: `}</p>
                        <p className={styles.nameClient}>{`Adress: `}</p>
                        <p className={styles.nameClient}>&nbsp;</p>
                    </div>
                    <div className={styles.title1Client}>
                        <p className={styles.nameClient}>
                            <b>
                            <span className={styles.spanClient}>Ghina Deni</span>
                            </b>
                        </p>
                    <p className={styles.nameClient}>
                        <b>
                        <span className={styles.spanClient}>+41 76 541 17 03</span>
                        </b>
                    </p>
                    <p className={styles.nameClient}>
                        <b>
                        <span className={styles.spanClient}>ghina-deni@gmail.com</span>
                        </b>
                    </p>
                    <p className={styles.nameClient}>
                        <b>
                        <span className={styles.spanClient}>{`Chemin de la Judée 23 B, 1032 `}</span>
                        <span className={styles.romanelSurLausanneClient}>Romanel sur Lausanne</span>
                        </b>
                    </p>
                    <p className={styles.blankLine1Client}>&nbsp;</p>
                    </div>
                    </div>
                </>
            )}
            {activeTab === 'historique' && (
                <>
                <div className={styles.generalInfo}>
                    Informations sur les clients
                </div>
                <div className={styles.frameParentHistory}>
                    <div className={styles.frameGroupHistory}>
                        <div className={styles.groupWrapperHistory}>
                            <div className={styles.ratingWrapperHistory}>
                                <div className={styles.ratingHistory}>
                                    <div className={styles.mayContainerHistory}>
                                        <p className={styles.mayHistory}>
                                            <b>{`04 May `}</b>
                                        </p>
                                        <TimelineName  eventData={data} />
                                        <p className={styles.pHistory}>2024 10:37</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.groupContainerHistory}>
                            <div className={styles.rectangleParentHistory}>
                                <div className={styles.groupChildHistory} />
                                <div className={styles.locationOnMapHistory}>Location on Map</div>
                                <div className={styles.rating1History}>
                                    <div className={styles.byRachelHistory}>By Rachel</div>
                                </div>
                                <div className={styles.rating2History}>
                                    <div className={styles.completedHistory}>COMPLETED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.frameContainerHistory}>
                        <div className={styles.groupWrapperHistory}>
                            <div className={styles.ratingWrapperHistory}>
                                <div className={styles.ratingHistory}>
                                    <div className={styles.mayContainerHistory}>
                                        <p className={styles.mayHistory}>
                                            <b>{`04 May `}</b>
                                        </p>
                                        <p className={styles.pHistory}>2024 10:37</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.groupContainerHistory}>
                            <div className={styles.rectangleParentHistory}>
                                <div className={styles.groupChildHistory} />
                                <div className={styles.locationOnMapHistory}>Location on Map</div>
                                <div className={styles.rating4History}>
                                    <div className={styles.byRachelHistory}>By Rachel</div>
                                </div>
                                <div className={styles.rating5History}>
                                    <div className={styles.completedHistory}>REACHED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.frameParent1History}>
                        <div className={styles.groupWrapperHistory}>
                            <div className={styles.ratingWrapperHistory}>
                                <div className={styles.ratingHistory}>
                                    <div className={styles.mayContainerHistory}>
                                        <p className={styles.mayHistory}>
                                            <b>{`04 May `}</b>
                                        </p>
                                        <p className={styles.pHistory}>2024 10:37</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.groupContainerHistory}>
                            <div className={styles.rectangleParentHistory}>
                                <div className={styles.groupChildHistory} />
                                <div className={styles.locationOnMapHistory}>Location on Map</div>
                                <div className={styles.rating7History}>
                                    <div className={styles.byRachelHistory}>By Rachel</div>
                                </div>
                                <div className={styles.rating8History}>
                                    <div className={styles.completedHistory}>STARTED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.frameParent2History}>
                        <div className={styles.groupWrapperHistory}>
                            <div className={styles.ratingWrapperHistory}>
                                <div className={styles.ratingHistory}>
                                    <div className={styles.mayContainerHistory}>
                                        <p className={styles.mayHistory}>
                                            <b>{`04 May `}</b>
                                        </p>
                                        <p className={styles.pHistory}>2024 10:37</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.groupContainerHistory}>
                            <div className={styles.rectangleParentHistory}>
                                <div className={styles.groupChildHistory} />
                                <div className={styles.locationOnMapHistory}>Location on Map</div>
                                <div className={styles.rating10History}>
                                    <div className={styles.byRachelHistory}>By Rachel</div>
                                </div>
                                <div className={styles.rating11History}>
                                    <div className={styles.completedHistory}>ACCEPTED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.frameParent3History}>
                        <div className={styles.groupWrapperHistory}>
                            <div className={styles.ratingWrapperHistory}>
                                <div className={styles.ratingHistory}>
                                    <div className={styles.mayContainerHistory}>
                                        <p className={styles.mayHistory}>
                                            <b>{`04 May `}</b>
                                        </p>
                                        <p className={styles.pHistory}>2024 10:37</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.groupContainerHistory}>
                            <div className={styles.rectangleParentHistory}>
                                <div className={styles.groupChildHistory} />
                                <div className={styles.harris31356History}>Harris - 31356</div>
                                <div className={styles.rating13History}>
                                    <div className={styles.byApiApiHistory}>{`By API- API - David `}</div>
                                </div>
                                <div className={styles.rating14History}>
                                    <div className={styles.completedHistory}>CREATED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </>
            )}
        </div>
    );
};

export default Commande;
