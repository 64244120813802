import React from 'react';
import { createRoot } from 'react-dom/client'; // Mettez à jour l'importation
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LoadingProvider } from './context/LoadingProvider';
import { NotificationProvider } from './context/NotificationProvider';

// Utilisez createRoot pour rendre votre application
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<LoadingProvider><NotificationProvider><App /></NotificationProvider></LoadingProvider>} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
