import React, { Fragment, useState } from 'react';
import MenuHeader from './MenuHeader';
import Breadcrumb from './Breadcumb/Breadcrumb';
import { Map } from './google_map';
import ListItem from './List_item/ListItem';
import ListTable from './TaskList/ListTable';
import { LoadScript } from '@react-google-maps/api';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Dashboard = () => {
  const [selectedView, setSelectedView] = useState('map');

  // Function to update selected view
  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  return (
    <Fragment>
      <MenuHeader />
      <Breadcrumb onViewChange={handleViewChange} selected={selectedView} />
      
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        {selectedView === 'map' && (
          <>
            <Map />
            <ListItem />
          </>
        )}
        {selectedView === 'list' && <ListTable />}
      </LoadScript>
    </Fragment>
  );
};

export default Dashboard;
