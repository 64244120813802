export const setupAuthorizationInterceptor = (axiosInstance, refreshToken, auth) => {
    const requestIntercept = axiosInstance.interceptors.request.use(
        (config) => {
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest?.sent) {
                prevRequest.sent = true;
                const newAccessToken = await refreshToken();
                prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(prevRequest);
            }
            return Promise.reject(error);
        }
    );

    // Cleanup function to remove interceptors
    return () => {
        axiosInstance.interceptors.request.eject(requestIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};
