import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from "../utils/utils";

const ForgetPassword = () => {
    const userRef = useRef();
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
        setSuccessMsg("");
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.get(
                `${endpoints.forgetUrl}?email=${encodeURIComponent(email)}`,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
        } catch (err) {

        }
    };

    return (
        <div className="main-login">
            <div className="container">
                <div className="admin-left">
                    <div className="logo">Flyspa</div>
                    <div className="form-login">
                        <form onSubmit={handleSubmit}>
                            {errMsg && <p className="error">{errMsg}</p>}
                            {successMsg && <p className="success">{successMsg}</p>}
                            <div className="form-text">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Your email address"
                                    className="email"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </div>
                            <div className="form-submit">
                                <input
                                    type="submit"
                                    name="reset"
                                    id="reset_password"
                                    value="Reset my password"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
