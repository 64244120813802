// ChooseLocation.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import { useNotifications } from '../context/NotificationProvider';
import { asTitle } from '../shared/utils/string';
import UserService from "../services/user.service";
import ZoneService from "../services/zone.service";
import cantons from "../shared/data/cantons.json";

const libraries = ['places'];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Flypro = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);
  const notify = useNotifications();

  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [zones, setZones] = useState([]); // État pour stocker les données des clients
  const [loading, setLoading] = useState(true);   // Pour afficher un état de chargement
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const limit = 100; // Nombre de clients par page
  const [formData, setFormData] = useState({
    civility: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    birthdate: "",
    street: "",
    street_number: "",
    city: "",
    zip_code: "",
    state_code: "",
    country: "Switzerland",
    country_code: "CH",
    type: "pro"
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk',
    libraries,
  });
  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };

  const [filters, setFilters] = useState({
    with: 'primaryaddress',
  });

  const handleChange = (e) => {
    if (e.target.name === 'state') {
      let selected = cantons.cantons.find(
        (canton) => canton.name === e.target.value
      );
      document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range);
      setFormData({
        ...formData, zip_code: ''
      });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zip_code: '',
    };

    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zip_code = component.long_name;
          break;
        default:
          break;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      street: newAddress.street,
      city: newAddress.city,
      zip_code: newAddress.zip_code,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );
    const [start, end] = state.zip_code_range.split("–").map(Number);
    if (formData.zip_code === "" || (formData.zip_code < start || formData.zip_code > end)) {
      notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
      setFormData({
        ...formData,
        zip_code: '',
      });
      return;
    }
    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.state_code = state?.abbreviation;
    formData.role = 'ROLE_FLYPRO';
    formData.country = "Switzerland";
    formData.country_code = "CH";
    try {
      const response = await UserService.store(formData, axiosPrivate);
      if (response.data) {
        closeModal();
        fetchCustomers();
      }
    } catch (e) {
      // errors handled in interceptor
    }

  };

  const fetchCustomers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate);
      setCustomers(response);
    } catch (error) {
    }
  };

  const fetchZone = async () => {
    try {
      const response = await ZoneService.index(null, axiosPrivate);

      setZones(response);

    } catch (error) {
    }
  };

  const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0]; // Formater la date en YYYY-MM-DD
  };
  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {

    fetchZone()
    fetchCustomers();
  }, [currentPage]); // Recharger les données à chaque changement de page

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function openModalManage() {
    setIsOpenManage(true);
  }

  function afterOpenModal() {

  }

  function afterOpenModalManage() {

  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalManage() {
    setIsOpenManage(false);
  }

  function closeModal() {
    setFormData({
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zip_code: "",
      type: ""
    });
    setIsOpen(false);
  }
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;
  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by City or Team/Zone..."
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <div className="select">
              <select>
                <option value="">Name</option>
                <option value="Name">name</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">Exact Search</option>
                <option value="Name">Exact Search</option>
              </select>
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Ajouter un Flypro
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Username</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Therapist score</th>
                <th>Team</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img src={image} alt="FlySpa" />
                    </td>
                    <td>{customer.firstname} </td>
                    <td>{customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>score</td>
                    <td>{customer?.zone?.name}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {customer.status}
                    </td>
                    <td>
                      <a onClick={openModalManage} className="edit">
                        &#8942;
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Aucun client trouvé.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="pagination-button"
            >
              Précédent
            </button>
            <span>
              Page {currentPage + 1} sur {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages - 1}
              className="pagination-button"
            >
              Suivant
            </button>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={closeModal}>close</button>
        <h2>Ajouter un Flypro</h2>
        <p>Si vous avez besoin de plus d'informations, veuillez consulter </p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input type="radio" name="civility" value="MS" id="MS" onChange={handleChange} />
                <label for="MS">Madame</label>
              </div>
              <div className="form-radio">
                <input type="radio" name="civility" value="MR" id="MR" onChange={handleChange} />
                <label for="MR">Monsieur</label>
              </div>
            </div>

            <div className="form-text">
              <label>Prénom *</label>
              <input type="text" name="firstname" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Nom *</label>
              <input type="text" name="lastname" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Numéro de téléphone portable *</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />
            </div>

            <div className="form-text">
              <label>Adresse email</label>
              <input type="email" name="email" onChange={handleChange} />
            </div>

            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Street Number</label>
                <input value={formData.street_number} type="text" name="street_number" onChange={handleChange} />
              </div>
              <div className="form-text">
                <label>City</label>
                <input value={formData.city} type="text" name="city" onChange={handleChange} />
              </div>
            </div>

            <div className="form-text">
              <label>Adresse</label>
              <Autocomplete
                apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
                onPlaceSelected={handlePlaceSelect}
                options={{
                  types: ['address'],
                  componentRestrictions: { country: 'CH' },
                }}
                style={{ width: '100%', padding: '0 15px', height: '60px', border: '0', borderRadius: '20px', boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)' }}
              />
            </div>

            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Canton</label>
                <select name="state" onChange={handleChange}>
                  <option value="">-- Select a Canton --</option>
                  {cantons.cantons.map((canton) => (
                    <option key={canton.abbreviation} value={canton.name}>
                      {canton.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-text">
                <label>Code ZIP</label>
                <input value={formData.zip_code} type="text" name="zip_code" onChange={handleChange} />
              </div>
            </div>

            <div className="form-select">
              <label>Therapist zone</label>
              <div className="select">
                <select>
                  <option value="">Therapist zone</option>
                  {zones.map((zone) => (
                    <option value={zone?.id}>{zone?.name}</option>
                  ))

                  }

                </select>
              </div>
            </div>

            <div className="form-select">
              <label>Gestionnaire</label>
              <div className="select">
                <select>
                  <option value="">Exact Search1</option>
                  <option value="Name">Exact Search2</option>
                </select>
              </div>
            </div>

            <button type="submit" className="btn-submit">
              Créer le compte
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenManage}
        onAfterOpen={afterOpenModalManage}
        onRequestClose={closeModalManage}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bloc-manage-ther">
          <h2>Manage Therapists</h2>
          <p>Currently Managed Therapists</p>
          <form>
            <ul>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <input type="checkbox" id="actif" />
                  <label htmlFor="actif"></label>
                </div>
              </li>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <input type="checkbox" id="actif1" />
                  <label htmlFor="actif1"></label>
                </div>
              </li>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <input type="checkbox" id="actif2" />
                  <label htmlFor="actif2"></label>
                </div>
              </li>
            </ul>

            <p>Available Therapists </p>
            <div className="bloc-search">
              <input type="text" className="form-text" placeholder="Search by Name" />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <ul>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <a href="#" className="btn-add">Add</a>
                </div>
              </li>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <a href="#" className="btn-add">Add</a>
                </div>
              </li>
              <li>
                <div className="image"><img src={image} alt="FlySpa" /></div>
                <div className="id">72789016</div>
                <div className="username">Radija khther</div>
                <div className="lieu">Genéve</div>
                <div className="action">
                  <a href="#" className="btn-add">Add</a>
                </div>
              </li>
            </ul>
            <button className="btn-save">Save Changes</button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Flypro;
