import axios from 'axios';
import Cookies from 'js-cookie';
import { endpoints } from "../utils/utils";

const csrfCookieEndpoint = process.env.REACT_APP_BACKEND_URL + endpoints.csrf;

// A helper to get the CSRF token from the cookies
function getCsrfToken() {
    const token = Cookies.get('XSRF-TOKEN');
    return token || null;
}

function getCsrfCookie() {
    return axios.get(csrfCookieEndpoint, { withCredentials: true });
}

export const setupCsrfInterceptor = (axiosInstance) => {
    // Add a request interceptor
    const requestCsrfIntercept = axiosInstance.interceptors.request.use(
        async (config) => {
            // If the request is to the CSRF endpoint or already has the token, do nothing
            if (config.url?.includes(csrfCookieEndpoint) || config.headers['X-XSRF-TOKEN']) {
                return config;
            }

            // Try to get the token from cookies
            let csrfToken = getCsrfToken();
            if (csrfToken) {
                config.withCredentials = true;
                config.headers['X-XSRF-TOKEN'] = csrfToken;
                return config;
            }

            // If no token, fetch it first
            await getCsrfCookie();

            // After fetching, try again
            csrfToken = getCsrfToken();
            if (csrfToken) {
                config.withCredentials = true;
                config.headers['X-XSRF-TOKEN'] = csrfToken;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    const responseIntercept = axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // If the error status is 419 and it's not a retry, handle the CSRF token reset
            if (error?.response?.status === 419 && !originalRequest._retry) {
                originalRequest._retry = true; // Mark the request as retried

                // Remove the XSRF-TOKEN cookie
                Cookies.remove('XSRF-TOKEN', { path: '', domain: process.env.REACT_APP_DOMAIN_URL });

                // Fetch a new CSRF cookie
                await getCsrfCookie();

                // Get the new CSRF token
                const csrfToken = getCsrfToken();
                if (csrfToken) {
                    originalRequest.withCredentials = true;
                    originalRequest.headers['X-XSRF-TOKEN'] = csrfToken;
                }

                // Retry the original request
                return axiosInstance(originalRequest);
            }

            return Promise.reject(error);
        }
    );

    // Function to clean up interceptors
    return () => {
        axiosInstance.interceptors.request.eject(requestCsrfIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};
