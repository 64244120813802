import CryptoJS from 'crypto-js';

const base64Key = process.env.REACT_APP_BASE64_KEY;
const key = CryptoJS.enc.Base64.parse(base64Key);

export const encrypt = (data) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return {
        encrypted,
        iv: CryptoJS.enc.Base64.stringify(iv),
    };
};

export const decrypt = (encryptedData, iv) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        iv: CryptoJS.enc.Base64.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decrypted;
};
