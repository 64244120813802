import { GoogleMap } from '@react-google-maps/api';

const containerStyle = {
  width: '100vw',
  height: '150vh',
};

const center = {
  lat: 46.356,
  lng: 6.5641,
};

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};

export const Map = () => {
  return (
    <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
          options={mapOptions}
        >
        </GoogleMap>
    </div>
  );
};