import React, { createContext, useState, useContext } from 'react';
import { hourglass } from 'ldrs';

hourglass.register();

const LoadingContext = createContext();

export const useLoading = () => {
    return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const start = () => setLoading(true);

    const stop = () => setLoading(false);

    return (
        <LoadingContext.Provider value={{ loading, start, stop }}>
            {loading && (
                <div className="loading-overlay">
                    <img src='assets/images/flyspa-logo.svg' alt='Flyspa Logo' />
                    <l-hourglass
                        size="40"
                        bg-opacity="0.1"
                        speed="1.75"
                        color="#b3bec5"
                    ></l-hourglass>
                </div>
            )}
            {children}
        </LoadingContext.Provider>
    );
};
