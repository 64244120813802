import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from "../utils/utils";
import { formatDistanceToNow } from 'date-fns';
import Swal from 'sweetalert2'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    height: '680px',
  },
};

const Notification = () => {
    Modal.setAppElement('#root');

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [notifications, setNotifications] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const limit = 100; // Nombre de clients par page
    const [currentPage, setCurrentPage] = useState(0); // Page actuelle
    const [totalPages, setTotalPages] = useState(1);   // Total des pages
    const [suggestions, setSuggestions] = useState([]);
    const [formData, setFormData] = useState({
        group: "",
        civility: "",
        message: "",
        titre: "",
        user_id: "",
      });

      const handleDelete = (id) => {

        console.log(`Delete ${id}`);
    
        // Envoyer les données du formulaire au serveur via Axios
        axiosPrivate
          .delete(endpoints.deleteNotification + "/" + id)
          .then((response) => {
            if(response.data.status == false){
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Une erreur est survenu! "+response.data.message,
                
              });
    
            }
              
            closeModal();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "notification supprimé",
              showConfirmButton: false,
              timer: 1500
            });
            console.log("Form submitted successfully:", response.data);
            fetchNotifications();
          })
          .catch((error) => {
            // Gérer les erreurs lors de l'envoi
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une erreur est survenu! "+error,
              
            });
            console.error("Error submitting form:", error);
          });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Envoyer les données du formulaire au serveur via Axios
        axiosPrivate
          .post(endpoints.newNotification , formData)
          .then((response) => {
            if(response.data.status == false){
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Une erreur est survenu! "+response.data.message,
                
              });
    
            }
              
            else
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "notification crée",
              showConfirmButton: false,
              timer: 1500
            });
            console.log("Form submitted successfully:", response.data);
            fetchNotifications();
            closeModal();
          })
          .catch((error) => {
            // Gérer les erreurs lors de l'envoi
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une erreur est survenu! "+error,
              
            });
            console.error("Error submitting form:", error);
          });
      };

      const TimeAgo = ({ date }) => {
        return (
          <span>
            {formatDistanceToNow(new Date(date), { addSuffix: true })}
          </span>
        );
      };

      const fetchNotifications = async () => {
        try {
          const response = await axiosPrivate.get(
            `${endpoints.getNotification}/?page=${currentPage+1}&limit=${limit}`
          ); // L'URL inclut la page et la limite
    
          if(response.data.status == false)
            setNotifications([])
          else
          setNotifications(response.data); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
         // setTotalPages(response.data.totalPages || 2); // Le nombre total de pages si disponible dans la réponse
          //
        } catch (error) {
          console.error("Erreur lors du chargement des notification:", error);
         // 
        }
      };

      useEffect(() => {
    

        fetchNotifications();
      }, [currentPage]);
    const handleChange = async (e) => {
      console.log(e.target.name, e.target.value)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "group" ) {
          setSelectedOption(e.target.value);
      }
      console.log("selectedOption",formData.group)
      if(e.target.name =="group" || e.target.name === "user_id"){
        const value = e.target.value;
        if (value.length > 2) { // Commencez à chercher après 2 caractères
          try {
           
              const response = await axiosPrivate.get(`${endpoints.getAdminUser}/?role=${formData.group == "oneSpecificClient" ? "ROLE_CLIENT" : "ROLE_PRO"}&page=${currentPage+1}&limit=${limit}&email=${value}`);
              setSuggestions(response.data.users); // Assurez-vous que le format des données est correct
              console.log(suggestions)
              
          } catch (error) {
              console.error('Erreur lors de la récupération des suggestions', error);
          }
      } else {
          setSuggestions([]); // Réinitialiser si moins de 3 caractères
      }
       
      }
        
    };

    const handleSuggestionClick = (suggestion) => {
      setFormData({ ...formData, user_id: suggestion });
      setSuggestions([]); // Effacer les suggestions après sélection
  };
  
    function openModal() {
      setIsOpen(true);
    }

    function afterOpenModal() {
  
    }
  
    function closeModal() {
      setFormData({
        group: "",
        civility: "",
        message: "",
        titre: "",
        user_id: "",
      })
      setIsOpen(false);
    }
  

    return (
        <>
            <MenuHeader />
            <div className="notification-breadcumb">
                <div className="container">
                    <button className="btn-send-notif" onClick={openModal}>
                        Send notification
                    </button>
                </div>
            </div>
            <div className="container">
                {  notifications?.map((notification) => (
                    <div className="notification">
                    <div onClick={() => handleDelete(notification.id)}  className="notification-close-btn">×</div>
                    {notification?.user?.image &&
                    <div className="profile-picture">
                        <img src={notification?.user?.image} alt="FlySpa" className="avatar-img" />
                    </div>
                    }
                    <div className="notification-card-column">
                        <div className="notification-card-header">
                            <div className="notification-card-title">
                                <div className="notification-card-title-text">{notification.titre}</div>
                            </div>
                            <div className="notification-card-time">
                                <div className="notification-card-title-time-text"><TimeAgo date={notification.date} /></div>
                            </div>
                        </div>
                        <div className="notification-card-content-wrapper">
                            <div className="notification-card-content">
                                <div className="notification-card-content-text">{notification.message}</div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
                
                
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Send notification"
            >
                <button onClick={closeModal}>close</button>
                <h2>Envoyer une notification</h2>
                <p>If you need more info, please check out </p>
                <div className="form-inscription">
                    <form onSubmit={handleSubmit}>
                        <div className="form-text">
                            <label>Send it to</label>
                            <select name="group" onChange={handleChange} value={formData.group}>
                                <option value="">Select...</option>
                                <option value="allFlypro">All flyPROs</option>
                                <option value="allClients">All clients</option>
                                <option value="oneSpecificFlyPRO">One specific flyPRO</option>
                                <option value="oneSpecificClient">One specific client</option>
                            </select>
                        </div>
                        {( selectedOption === "allClients") && (
                            <div className="form-choix flex-start">
                                <div className="form-radio">
                                    <input type="radio" name="civility" value="mr" id="hommes" onChange={handleChange} />
                                    <label htmlFor="hommes">Hommes</label>
                                </div>
                                <div className="form-radio">
                                    <input type="radio" name="civility" value="mrs" id="femmes" onChange={handleChange} />
                                    <label htmlFor="femmes">Femmes</label>
                                </div>
                            </div>
                        )}
                        {selectedOption === "oneSpecificFlyPRO" && (
                            <div className="form-text">
                                <label>FlyPRO email</label>
                                <input type="text" name="user_id" onChange={handleChange} value={formData.user_id} />
                            </div>
                        )}
                        {selectedOption === "oneSpecificClient" && (
                            <div className="form-text">
                                <label>Client email</label>
                                <input type="text" name="user_id" onChange={handleChange} value={formData.user_id} />
                            </div>
                        )}
                        {suggestions.length > 0 && (
                          <ul className="suggestions-list">
                              {suggestions.map((suggestion, index) => (
                                  <li key={index} onClick={() => handleSuggestionClick(suggestion.email)}>
                                      {suggestion.email} {/* Ajustez selon la structure de vos données */}
                                  </li>
                              ))}
                          </ul>
                      )}
                        <div className="form-text">
                                <label>Title</label>
                                <input type="text" name="titre" onChange={handleChange} value={formData.titre} />
                            </div>
                        <div className="form-text">
                            <label>Your message</label>
                            <textarea
                                className="formTextArea"
                                name="message"
                                onChange={handleChange}
                                value={formData.message}
                            />
                        </div>
                        <button type="submit" className="btn-submit">
                            Send Notification
                        </button>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Notification;