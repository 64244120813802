import ApiService from "./api.service";
export default class ProductService extends ApiService {
    static base_url = `${super.base_url}/products`;

    static async store(data, axiosInstance) {
        const response = await axiosInstance.post(`${this.base_url}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
    }
}
