import React, { useState } from "react";
import "./ListItem.css";
import ListImage  from "../ListItemImage/ListItemImage";
import Commande from "../Commande/Commande";

const ListItem = ({ date, time, title, description, status }) => (
  <div className="item">
    <div className="item-content">
      <div className="date">
        <span>
          <span>{date}</span>
          <span> | </span>
          <span>{time}</span>
        </span>
      </div>
      <div className="item_title">{title}</div>
      <div className="item_description">{description}</div>
    </div>
    <div className="status-container">
      <div className="status-badge">
        <div className="status-label">{status}</div>
      </div>
    </div>
  </div>
);

export default function List() {
  const [activeTab, setActiveTab] = useState("Non assigné");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showCommande, setShowCommande] = useState(false);

  const items = [
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },    
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
    {
      date: "04/07/2024",
      time: "11:00 - 12:30",
      title: "Forem ipsum",
      description: "Porem ipsum dolor sit amet",
      status: "Non assigné",
    },
  ];

  const filteredItems = items.filter((item) => item.status === activeTab);

  return (
    <div className="list-item-with-image">
      <div className="frame-14">
        <div className="title">Les commandes</div>
        <div className="glyph" />
      </div>
      <div className="tabs">
        <div className="tab-group">
          <div
            className={`tab-1 ${activeTab === "Non assigné" ? "active" : ""}`}
            onClick={() => setActiveTab("Non assigné")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Non assigné</div>
                {activeTab === "Non assigné" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`tab-2 ${activeTab === "Assigné" ? "active" : ""}`}
            onClick={() => setActiveTab("Assigné")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Assigné</div>
                {activeTab === "Assigné" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`tab-3 ${activeTab === "Terminé" ? "active" : ""}`}
            onClick={() => setActiveTab("Terminé")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Terminé</div>
                {activeTab === "Terminé" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeTab === "Non assigné" && (
        <div className="list-items">
          {filteredItems.map((item, index) => (
            <ListItem
              key={index}
              date={item.date}
              time={item.time}
              title={item.title}
              description={item.description}
              status={item.status}
            />
          ))}
        </div>
      )}
      {activeTab === "Assigné" && (
        <div className="list-items">
          {filteredItems.map((item, index) => (
            <div className="list-items"
              key={index}
              onClick={() => {
                setSelectedItem(item);
                setShowCommande(true);
              }}
            >
              <ListImage 
                date={item.date}
                time={item.time}
                title={item.title}
                description={item.description}
                status={item.status}
              />
            </div>
          ))}
        </div>
      )}
      
      {activeTab === "Terminé" && <div>Done !</div>}

      {showCommande && selectedItem && (
        <div
          onMouseEnter={() => setShowCommande(true)}
          onMouseLeave={() => setShowCommande(false)}
        >
          <Commande   
            orderId="16728920"
            status="Successful"
            taskDescription="Anti-stress [LP]"
            startTime="04 MAY 2024 11:00"
            completeTime="04 MAY 2024 12:30"
            team="Lavaux"
            pro="Rachael"
            orderID="VALUXMTJKOVE"
            totalTime="2 hours"
            distance="11.40 Kms"
            item={selectedItem} 
          />
        </div>
      )}
    </div>
  );
}
