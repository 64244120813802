import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2';
import UserService from "../services/user.service";
import cantons from "../shared/data/cantons.json";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNotifications } from '../context/NotificationProvider';
import { asTitle } from '../shared/utils/string';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
    maxHeight: "95vh",
    scrollbarWidth: "none",
  },
};

const User = () => {

  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = useState(false);
  const notify = useNotifications();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    street: "",
    street_number: "",
    city: "",
    type: "admin",
    civility: "",
    birthdate: "",
    state_code: "",
    zip_code: "",
    country: "Switzerland",
    country_code: "CH",
  });

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };

  const [filters, setFilters] = useState({
    with: 'primaryaddress,roles',

  });

  const axiosPrivate = useAxiosPrivate();

  const fetchUsers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_ADMIN,ROLE_MANAGER', axiosPrivate);
      console.log('the results of resps', response);
      setUsers(response);

    } catch (error) {
    }
  };


  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === 'state') {
      let selected = cantons.cantons.find(
        (canton) => canton.name === e.target.value
      );
      document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range);
      setFormData({
        ...formData, zip_code: ''
      });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );

    const [start, end] = state.zip_code_range.split("–").map(Number);
    if (formData.zip_code === "" || (formData.zip_code < start || formData.zip_code > end)) {
      notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
      setFormData({
        ...formData,
        zip_code: '',
      });
      return;
    }
    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.state_code = state?.abbreviation;
    formData.role = formData.type === 'admin' ? 'ROLE_ADMIN' : 'ROLE_MANAGER';
    formData.country = "Switzerland";
    formData.country_code = "CH";
    try {
      const response = await UserService.store(formData, axiosPrivate);
      if (response.data) {
        closeModal();
        fetchUsers();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const openModal = () => {
    setIsOpen(true);
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      type: "",
      civility: "",
      birthdate: "",
      zip_code: "",
    });
  };

  const closeModal = () => {
    setFormData({
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zip_code: "",
      type: ""
    });
    setIsOpen(false);
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                placeholder="Search by City or Team/Zone..."
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Add a manager
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Registration Date</th>
                <th>Team</th>
                <th>status</th>
                <th>Role</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <img src={image} alt="User Profile" />
                    </td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{user.created_at}</td>
                    <td>{user.therapistScore}</td>
                    <td className={user?.status?.toLowerCase()}>
                      {asTitle(user.status)}
                    </td>
                    <td>{asTitle(user.roles[0].name.split('_')[1])}</td>
                    <td>
                      <a href="#" className="edit">&#8942;</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No user found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Manager"
      >
        <button onClick={closeModal}>Close</button>
        <h2>Add a user</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MS"
                  id="madame"
                  checked={formData.civility === "MS"}
                  onChange={handleChange}
                />
                <label htmlFor="madame">Ms</label>
              </div>
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MR"
                  checked={formData.civility === "MR"}
                  id="monsieur"
                  onChange={handleChange}
                />
                <label htmlFor="monsieur">Mr</label>
              </div>
            </div>
            <div className="form-text">
              <label>First Name</label>
              <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Last Name</label>
              <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Date of Birth (optional)</label>
              <input type="date" name="birthdate" value={formData.birthdate} onChange={handleChange} />
            </div>
            <div className="form-text">
              <label>Phone Number</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                ariaHideApp="false"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />            </div>
            <div className="form-text">
              <label>Email Address</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Street Number</label>
                <input value={formData.street_number} type="text" name="street_number" onChange={handleChange} />
              </div>
              <div className="form-text">
                <label>City</label>
                <input value={formData.city} type="text" name="city" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Street</label>
              <input value={formData.street} type="text" name="street" onChange={handleChange} />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Canton</label>
                <select name="state" onChange={handleChange}>
                  <option value="">-- Select a Canton --</option>
                  {cantons.cantons.map((canton) => (
                    <option key={canton.abbreviation} value={canton.name}>
                      {canton.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-text">
              <label>Code ZIP</label>
              <input value={formData.zip_code} type="text" name="zip_code" onChange={handleChange} />
            </div>

            <div className="form-select">
              <label>Status</label>
              <select name="role" value={formData.role} onChange={handleChange} required>
                <option value="">Select a role</option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_ADMIN">Manager</option>
                <option value="ROLE_ADMIN">User</option>
              </select>
            </div>
            <button type="submit" className="btn-submit">
              Create a manager
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default User;
