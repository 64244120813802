import React, { Fragment } from 'react';
import styles from './ListTable.module.css';

export default function TaskList() {
  const sampleData = [
    { task: '522891911', order: 578700, team: 'New York', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891912', order: 578701, team: 'San Francisco', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891911', order: 578700, team: 'New York', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891912', order: 578701, team: 'San Francisco', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
  ];

  // Table component
  const Table = ({ data }) => {
    return (
      <div className={styles.tableContainer}>
        <table className={styles.customTable}>
          <thead className={styles.tableHeader}>
            <tr>
              <th className={styles.tableHeaderCell}>Task ID</th>
              <th className={styles.tableHeaderCell}>ORDER ID</th>
              <th className={styles.tableHeaderCell}>TEAM NAME</th>
              <th className={styles.tableHeaderCell}>TAGS</th>
              <th className={styles.tableHeaderCell}>DESCRIPTION</th>
              <th className={styles.tableHeaderCell}>FLYING DETAILS</th>
              <th className={styles.tableHeaderCell}>NAME</th>
              <th className={styles.tableHeaderCell}>ADDRESS</th>
              <th className={styles.tableHeaderCell}>CREATED BY</th>
              <th className={styles.tableHeaderCell}>START BEFORE</th>
              <th className={styles.tableHeaderCell}>COMPLETE BEFORE</th>
              <th className={styles.tableHeaderCell}>REVIEW / RATING</th>
              <th className={styles.tableHeaderCell}>TASK STATUS</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className={styles.tableRow}>
                <td className={`${styles.tableCell} ${styles.taskColor}`}>{item.task}</td>
                <td className={styles.tableCell}>{item.order}</td>
                <td className={styles.tableCell}>{item.team}</td>
                <td className={styles.tableCell}>{item.tags}</td>
                <td className={styles.tableCell}>{item.description}</td>
                <td className={styles.tableCell}>{item.flyingDetails}</td>
                <td className={styles.tableCell}>{item.name}</td>
                <td className={styles.tableCell}>{item.address}</td>
                <td className={styles.tableCell}>{item.createdBy}</td>
                <td className={styles.tableCell}>{item.startBefore}</td>
                <td className={styles.tableCell}>{item.completeBefore}</td>
                <td className={styles.tableCell}>{item.reviewRating}</td>
                <td className={styles.tableCell}>{item.taskStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Fragment>

      {/* Filter Buttons */}
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <div className="select">
              <select>
                <option value="">Name</option>
                <option value="Name">name</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">Contains</option>
                <option value="Name">Contains</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">All Filters</option>
                <option value="Name">All Filters</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">All Tags</option>
                <option value="Name">All Tags</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Table */}
      <div className={styles.scrollableTableContainer}>
        <Table data={sampleData} />
      </div>
    </Fragment>
  );
}
