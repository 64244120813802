export const setupLoadingInterceptor = (axiosInstance, loader) => {

    const requestIntercept = axiosInstance.interceptors.request.use(config => {
        loader.start();
        return config;
    }, error => {
        loader.stop();
        return Promise.reject(error);
    });

    const responseIntercept = axiosInstance.interceptors.response.use(response => {
        loader.stop();
        return response;
    }, error => {
        loader.stop();
        return Promise.reject(error);
    });

    // Cleanup function to remove interceptors
    return () => {
        axiosInstance.interceptors.request.eject(requestIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};
